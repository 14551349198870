<template>
  <div class="content">
    <img src="../assets/head.png" alt="" />
    <div class="product-list">
      <div class="title">全部产品</div>
      <!-- 产品列表 -->
      <div class="list">
        <div class="product" v-for="(item, index) in products" :key="item.id">
          <div class="text-some">
            <img :src="item.src" alt="" />
            <div class="show-text">
              <div class="name">{{ item.name }}</div>
              <div class="describe">
                {{ item.describe }}
              </div>
            </div>
          </div>
          <div v-if="index == 0" class="btn">
            <a target="_blank" href="https://zhitou.zhishangsoft.com">去体验</a>
          </div>

          <div v-if="index == 1" class="btn">
            <a target="_blank" href="http://sucaiwang.zhishangsoft.com"
              >去体验</a
            >
          </div>
          <div class="btn" v-if="index == 2">
            <a target="_blank" href="http://zs.zhishangsoft.com">去体验</a>
          </div>
          <div v-if="index >= 3" class="btn" @click="changeShow(index)">
            下载
          </div>
        </div>
      </div>
    </div>
    <div class="mask" ref="mask" v-if="showEwm"></div>
    <div class="tc-tip-new" v-if="showEwm">
      <div class="cnt"><img src="../assets/ewm.jpg" alt="" /></div>
      <div class="text">请联系客服获取</div>
      <img @click="tcClose" class="gb" src="../assets/close.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          id: "as",
          name: "智投手",
          src: require("@/assets/zts.png"),
          describe:
            "抖音丨快手丨微博丨微信丨淘宝丨拼多多丨京东丨多平台信息流广告智能投放工具",
        },
        {
          id: "ab",
          name: "云视频管家",
          src: require("@/assets/yunvideo.png"),
          describe:
            "云整合素材库丨图片素材丨视频素材丨让灵感、创作到爆款更简单~",
        },
        {
          id: "ass",
          name: "知识平台",
          src: require("@/assets/knowledge.png"),
          describe: "只是沉淀丨知识共享丨知识问答丨协同成长、将知识转化为财富",
        },
        {
          id: "ads",
          name: "直播工具",
          src: require("@/assets/live.png"),
          describe: "主账号滚屏发言丨商品弹窗丨自动补库存",
        },
        {
          id: "azs",
          name: "视频混剪工具",
          src: require("@/assets/montage.png"),
          describe:
            "一款简易的视频混剪工具，根据视频切片，批量生成抽帧混剪视频",
        },
        {
          id: "aws",
          name: "评论置顶工具",
          src: require("@/assets/comment.png"),
          describe: "该软件支持置顶多条评论",
        },
      ],
      showEwm: false,
    };
  },
  methods: {
    changeShow() {
      this.showEwm = true;
    },
    tcClose() {
      this.showEwm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0rem 2.4rem 1.5rem;
  img {
    display: block;
    width: 12rem;
    height: 2.8rem;
    margin-bottom: 0.44rem;
  }
}
.product-list {
  .title {
    text-align: left;
    font-size: 0.24rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #282828;
    line-height: 0.36rem;
  }
  .list {
    .product {
      height: 0.86rem;
      box-sizing: border-box;
      padding: 0.1rem 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 0.01rem solid #eeeeee;
      .text-some {
        display: flex;
      }
      img {
        width: 0.66rem;
        height: 0.66rem;
        margin-right: 0.25rem;
      }
      .show-text {
        line-height: 1;
        text-align: left;
        .name {
          margin-top: 0.15rem;
          margin-bottom: 0.1rem;
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #282828;
        }
        .describe {
          font-size: 0.14rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
      .btn {
        cursor: pointer;
        margin-top: 0.16rem;
        width: 1.08rem;
        height: 0.36rem;
        background: #ffffff;
        border: 0.01rem solid #0072ec;
        border-radius: 0.04rem;
        font-size: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0072ec;
        line-height: 0.36rem;
        text-align: center;
        a {
          text-decoration: none;
          color: #0072ec;
        }
      }
    }
  }
}
.mask {
  background-color: rgb(0, 0, 0);
  opacity: 0.6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
}
.tc-tip-new {
  z-index: 999;
  opacity: 1 !important;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  width: 5.62rem;
  height: 5.01rem;
  top: 25%;
  box-sizing: border-box;
  background: white;

  border-radius: 0.1rem;
  .cnt {
    margin-top: 1.01rem;
    margin-left: 1.67rem;
    margin-bottom: 0.55rem;
    width: 2.37rem;
    height: 2.37rem;
    background: #ffffff;
    border: 0.03rem solid #282828;
    border-radius: 0.1rem;
    display: flex;
    justify-content: center;
    box-shadow: 0.14rem 0.14rem #ffcd21, -0.14rem -0.14rem #ffcd21;
    img {
      margin-top: 0.12rem;
      width: 2.16rem;
      height: 2.16rem;
    }
  }
  .text {
    font-size: 0.24rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 0.36rem;
  }
  .gb {
    position: absolute;
    top: 5.2rem;
    left: 2.6rem;
    width: 0.42rem;
    height: 0.42rem;
  }

  // background: url(../../../../static/images/yun.png) center center no-repeat;
  p {
    margin-top: 0.3rem;
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 0.04rem;
      width: 0.26rem;
      height: 0.26rem;
    }
  }
}
</style>
